// import { user_context } from 'contexts';
import { useUser } from 'contexts';
import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';
import { CardHeader } from '@mui/material';
import { create_charging_schedule_mutation } from 'queries';
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridRowEditStopReasons
} from '@mui/x-data-grid';
import * as React from 'react';

import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import auth_config from "../auth_config.json";

import {
  GridToolbarContainer
} from '@mui/x-data-grid';

import { useAuth0 } from '@auth0/auth0-react';
import { GraphQLClient } from 'graphql-request';

// import { useAuth0 } from '@auth0/auth0-react';
// import auth_config from "../auth_config.json";
import { useEffect, useState } from 'react';
// import { useSchedules } from 'contexts';

// import { Button, Box } from '@mui/material';

import { edit_schedule, get_depot_schedules } from "queries";

import GemDrawerMenu from 'components/common/DrawerMenu';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { render } from '@testing-library/react';
import { X } from '@mui/icons-material';
import { set, update } from 'lodash';


function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
const ac = new AbortController();
const endpoint = (process.env.REACT_APP_GEMINI_URL || "https://gemini.orionair.com.au/graphql").trim();

// const renderDayCell = (params) => {
//   const isDisabled = params.row.dateFrom !== undefined;
//   return (
//     <input
//       type="checkbox"
//       checked={params.value}
//       disabled={isDisabled}
//       onChange={(e) => {
//         const newValue = e.target.checked;
//         // const newRow = { ...params.row, dayMon: newValue };
//         // handleProcessRowUpdate(newRow);
//       }}
//     />
//   );
// };


// const setStartDate = (value, row) => {
//   const d = new Date(value);
//   console.log("setStartDate", value, d);
//   return { ...row, startDate: d };
// };

// const setEndDate = (value, row) => {
//   const d = new Date(value);
//   console.log("setStartDate", value, d);
//   return { ...row, endDate: d };
// };


const parseEndDate = (value) => {
  console.log("parseEndDate", value);
  return new Date(value);
};

// Convert String from graphql to an actual date.
const getDate = (value, row) => {
  // console.log("getStartDate", value);
  if (value === undefined || value === null) {
    // console.log("getStartDate undefined or null", value);
    return value;
  }
  const x = new Date(value);
  // console.log("getStartDate", x);
  return x;

};

const setScheduledChargingStartTime = (value, row) => {
  console.log("setStartTime", value);
  if (value === undefined || value === null) {
    return value;
  }
  const [hours, minutes] = value.split(':');
  const now = new Date();
  const dateWithTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
  return { ...row, scheduledChargingStartTime: dateWithTime };
};

const setScheduledChargingEndTime = (value, row) => {
  console.log("setStartTime", value);
  if (value === undefined || value === null) {
    return value;
  }
  const [hours, minutes] = value.split(':');
  const now = new Date();
  const dateWithTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
  return { ...row, scheduledChargingEndTime: dateWithTime };
};


const parseTime = (value) => {
  console.log("parseStartTime", value);
  const [hours, minutes] = value.split(':');
  const now = new Date();
  const dateWithTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
  return dateWithTime;
};

// Convert String from graphql, to value for the grid.
const getTime = (value, row) => {
  // console.log("getStartTime", value);
  if (value === undefined || value === null) {
    return value;
  }
  const d = new Date(value);
  return d.getHours().toString().padStart(2, 0) + ":" + d.getMinutes().toString().padStart(2, 0);
  // return new Date(value).getTime();
};





/**
 * EditToolbar component.
 * 
 * Adds an "ADD RECORD" button above the table. 
 * 
 * @param {Object} props - The component props.
 * @param {Function} props.setRows - The function to set the rows.
 * @param {Function} props.setRowModesModel - The function to set the row modes model.
 * @returns {JSX.Element} The rendered EditToolbar component.
 */
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleAddRecordClick = () => {
    // const id = randomId();
    const id = 0;
    // debugger;
    setRows((oldRows) => [
      ...oldRows,
      {
        id: id,
        name: "new schedule",
        chargeCurrentLimit: 60,
        enabled: true,
        scheduledChargingStartTime: new Date(),
        scheduledChargingEndTime: new Date(new Date().getTime() + 60 * 60 * 1000),
        scheduledChargingEndSoc: 100,
        completionCondition: "ByTime",
      }
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleAddRecordClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

// window.confirm is kinda ugly, but will do for now.
// Later: Investigation options: https://codesandbox.io/p/sandbox/material-demo-cspqy?file=%2FAlertDialog.js
function SchedulesPage() {
  // const { context: user_context, register_user } = useUser();
  // const { user } = user_context;
  // useEffect(() => {
  //   register_user();
  // }, [user]); // eslint-disable-line react-hooks/exhaustive-deps
  // console.log("SchedulesPage", user);

  let { depot_id } = useParams();

  const [rowModesModel, setRowModesModel] = React.useState({});
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    if (window.confirm("Are you sure you want to save this record?")) {
      const row = rows.find((row) => row.id === id);
      row.depot = parseInt(depot_id);

      if (row.endDate instanceof Date) {
        row.endDate = row.endDate.getFullYear() + "-" + (row.endDate.getMonth() + 1).toString().padStart(2, 0) + "-" + row.endDate.getDate().toString().padStart(2, 0);
      }

      if (row.startDate instanceof Date) {
        row.startDate = row.startDate.getFullYear() + "-" + (row.startDate.getMonth() + 1).toString().padStart(2, 0) + "-" + row.startDate.getDate().toString().padStart(2, 0);
      }

      if (id === 0) {
        console.log("handleSaveClick create", row, id);
        createSchedule(row);
      } else {
        console.log("handleSaveClick update", row, id);
        updateSchedule(row)
      }
    }
  };

  const handleDeleteClick = (id) => () => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      updateSchedule({ id: id, deleted: true });
      // setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  // console.log("About to getColumns");
  // const columns = getColumns(rowModesModel, handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick, user);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };


  const { getAccessTokenSilently, error: auth0_error, user: auth0_user, isLoading } = useAuth0();
  let accessToken = null;
  useEffect(() => {
    const fetchData = async () => {
      try {
        accessToken = await getAccessTokenSilently({
          audience: auth_config.audience,
          scope: auth_config.scope
        });
        // console.log("fetchScheduleWithAuth", accessToken, depot_id, new Date().toISOString());
        const result = await fetchSchedulesWithAuth(accessToken, depot_id);
        // console.log("Result depotChargingSchedules", result.depotChargingSchedules, new Date().toISOString());
        if (Object.keys(rowModesModel).every(id => rowModesModel[id].mode !== GridRowModes.Edit)) {
          setRows(result.depotChargingSchedules);
          setUser(result.currentUser);
        } else {
          console.log("Not setting rows, as some are in edit mode.");
        }
      } catch (error) {
        console.error('Error fetching data:', error, accessToken, new Date().toISOString());
        toast.error('Error fetching data: ' + (typeof error === 'string' ? error : error.response.errors[0].message));
        // alert('Error fetching data: ' + error.message);
      }
    };
    fetchData();

    const interval = setInterval(() => {
      // console.log("Refetch data ");
      fetchData();
    }, 40000);

    return () => {
      // These cause some kind of loading race condition.
      clearInterval(interval);
    };
  }, [rowModesModel]);

  /**
   * Updates a schedule with the provided data.
   * 
   * This function makes a mutation request to the server to update the schedule with the provided data.
   * 
   * @param {Object} newRow - The new data for the schedule.
   * @returns {Promise<void>} - A promise that resolves when the schedule is updated successfully.
   * @throws {Error} - If there is an error updating the schedule.
   * 
   * For delete, if will just have id, and deleted: true
   */
  async function updateSchedule(newRow, deleted = false) {
    const toastId = toast.loading('Saving schedule ...');
    try {
      const accessToken = await getAccessTokenSilently({
        audience: auth_config.audience,
        scope: auth_config.scope,
      }
      );

      const client = new GraphQLClient(endpoint, { headers: { "Authorization": `Bearer ${accessToken}` }, signal: ac.signal });
      let id = parseInt(newRow["id"]);
      const updatedRow = { ...newRow };

      delete updatedRow["id"];
      delete updatedRow["vehicles"];
      delete updatedRow["vehicleCount"];

      console.log("About to call edit_schedule", id, updatedRow);
      const data = await client.request(edit_schedule, { id: id, input: updatedRow });

      console.log("Schedule Saved", updatedRow, data);
      toast.update(toastId, { type: "success", render: "Schedule saved", isLoading: false, autoClose: 5000 });
      if (deleted) {
        setRows(rows.filter((row) => row.id !== id));
      } else {
        setRows(rows.map((row) => (row.id === id ? { ...row, ...updatedRow } : row)));
      }
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      console.log("Schedule Saved, returning true");
      return true;
    } catch (error) {
      console.error('Error updating schedule:', error);
      toast.update(toastId, { type: "error", render: "Error saving schedule: " + error.response.errors[0].message, isLoading: false, autoClose: 10000 });
      console.log("Schedule Saved Error, returning false");
      return false;
    }
  }

  async function createSchedule(newRow) {
    const toastId = toast.loading('Creating schedule ...');
    try {
      const accessToken = await getAccessTokenSilently({
        audience: auth_config.audience,
        scope: auth_config.scope,
      }
      );

      const client = new GraphQLClient(endpoint, { headers: { "Authorization": `Bearer ${accessToken}` }, signal: ac.signal });
      // let id = parseInt(newRow["id"]);
      const updatedRow = { ...newRow };

      delete updatedRow["id"];
      delete updatedRow["vehicles"];
      delete updatedRow["vehicleCount"];

      console.log("About to call create_charging_schedule_mutation", updatedRow);
      const data = await client.request(create_charging_schedule_mutation, { input: updatedRow });
      const new_id = parseInt(data.createChargingSchedule.id);
      setRows(rows.map((row) => {
        if (row.id === 0) {
          console.log("Updating row with new_id:", new_id);
          return { ...row, id: new_id };
        } else {
          return row;
        }
      }));
      console.log("Schedule Saved", rows, new_id);
      setRowModesModel({ ...rowModesModel, [new_id]: { mode: GridRowModes.View } });
      // newRow = { ...newRow, id: new_id };
      // console.log("Schedule Saved", updatedRow, data);
      toast.update(toastId, { type: "success", render: "Schedule saved", isLoading: false, autoClose: 5000 });
      return data;
    } catch (error) {
      console.error('Error updating schedule:', error);
      toast.update(toastId, { type: "error", render: "Error saving schedule: " + error.message, isLoading: false, autoClose: 10000 });
    }
  }




  const [rows, setRows] = useState([]);
  const [user, setUser] = useState(null);

  console.log("About to getColumns");
  const columns = getColumns(rowModesModel, handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick, user);


  const handleProcessRowUpdate = (newRow) => {
    console.log("handleProcessRowUpdate", newRow);
    newRow.endDate = newRow.endDate ? newRow.endDate : null;
    const updatedRows = rows.map((row) =>
      row.id === newRow.id ? { ...row, ...newRow } : row
    );
    setRows(updatedRows);
    return newRow;
  };

  return (
    <Box display="flex" width="100%">
      <GemDrawerMenu />
      <Box
        sx={{ flexGrow: 1, padding: '5px' }}>
        <Card>
          <Box sx={{ backgroundColor: '#07A0C3', width: '100%', padding: '0px' }}>
            <CardHeader title="Edit Schedules" />
          </Box>

          <DataGrid rowHeight={32} sx={{ height: "100%", backgroundColor: "#ffffff" }}
            rows={rows}
            columns={columns}
            pageSize={5}
            disableRowSelectionOnClick
            processRowUpdate={handleProcessRowUpdate}     // Updates the internal state of the row. Not the database.
            experimentalFeatures={{ newEditingApi: true }}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            onCellDoubleClick={(params, event) => {
              preventDefaultIfNotInEditMode(params, event);
            }}
            onCellKeyDown={(params, event) => {
              preventDefaultIfNotInEditMode(params, event);
            }}

          />
        </Card>
      </Box>
    </Box>
  );

  /**
   * Prevents the default behavior of an event if the row is not in edit mode.
   *
   * @param {Object} params - The parameters object.
   * @param {Event} event - The event object.
   */
  function preventDefaultIfNotInEditMode(params, event) {
    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
    if (!isInEditMode) {
      event.defaultMuiPrevented = true;
    }
  }
}

export default SchedulesPage;

function getColumns(rowModesModel, handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick, user) {
  console.log("getColumns", user);
  const dayWidth = 30;
  return [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'name', headerName: 'Name', width: 400, editable: true },
    { field: 'chargeCurrentLimit', headerName: 'kW', type: 'number', width: 70, editable: true },
    {
      field: 'startDate', headerName: 'Start Date', type: 'date', width: 120, editable: true,
      valueGetter: getDate,
      // valueSetter: setStartDate,
      // valueParser: parseDate,
    },
    {
      field: 'endDate', headerName: 'End Date', type: 'date', width: 120, editable: true,
      valueGetter: getDate,
      // valueSetter: setEndDate,
      // valueParser: parseEndDate,
    },
    { field: 'monday', headerName: 'M', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: true },
    { field: 'tuesday', headerName: 'T', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: true, },
    { field: 'wednesday', headerName: 'W', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: true, },
    { field: 'thursday', headerName: 'T', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: true, },
    { field: 'friday', headerName: 'F', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: true, },
    { field: 'saturday', headerName: 'S', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: true, },
    { field: 'sunday', headerName: 'S', type: "boolean", minWidth: dayWidth, width: dayWidth, editable: true, },

    // { field: 'canStartTime', headerName: 'Can Start', width: 100, editable: true },
    {
      field: 'scheduledChargingStartTime', headerName: 'Start Time', width: 100, editable: true,
      valueGetter: getTime,
      valueSetter: setScheduledChargingStartTime,
      // valueParser: parseTime 
    },
    {
      field: 'scheduledChargingEndTime', headerName: 'End Time', width: 100, editable: true,
      valueGetter: getTime,
      valueSetter: setScheduledChargingEndTime,
      // valueParser: parseTime
    },
    { field: 'scheduledChargingEndSoc', headerName: 'SoC', type: 'number', width: 60, editable: true },
    { field: 'completionCondition', headerName: 'End', type: 'singleSelect', width: 100, editable: true, valueOptions: ['ByTime', 'ByStateOfCharge'] },
    { field: 'enabled', headerName: 'Enabled', type: 'boolean', width: 100, editable: true },
    // { field: 'depot', headerName: 'Depot', width: 150, editable: true, 
    //   renderCell: (params) => {
    //     return params.row.depot.name;
    //   }
    //  },
    { field: 'vehicleCount', headerName: 'No. Vehicles', width: 100, editable: false },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              className="textPrimary"
              onClick={handleDeleteClick(id)}
              color="inherit" />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit" />,
          ];
        }

        return user.isAdmin ? [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit" />,
        ] : [];
      },
    }
  ];
}

async function fetchSchedulesWithAuth(accessToken, depotId) {
  const client = new GraphQLClient(endpoint, { headers: { "Authorization": `Bearer ${accessToken}` }, signal: ac.signal });
  console.log("About to call get_depot_schedules", depotId, new Date().toISOString());
  const result = client.request(get_depot_schedules, { depotId: depotId });

  console.log("Result", result);
  return result;
}

