import { ToastContainer } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { List, ListItem } from "@mui/material";
import { getTextWidth } from "classes";
import { useLocation } from "react-router-dom";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

import { styled } from '@mui/material/styles';
import { OptionsPanelLogo } from "components/common";
import { DirectionsBus, PinDrop, Router, Schedule, Warning } from "@mui/icons-material";
import MailIcon from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ConnStatus } from "components/common";
import { useDepots } from "contexts";
import { Link } from 'react-router-dom';
import path_config from "../../path_config.json";
import { red } from '@mui/material/colors';


const StyledList = styled(List)({
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: '#2d5075',
    // '&, & .MuiListItemIcon-root': {
    //   color: 'pink',
    // },
  },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: '#FFFFFF',
    // '&, & .MuiListItemIcon-root': {
    //   color: 'yellow',
    // },
  },
});

export default function GemDrawerMenu() {
  // console.log("GemDrawerMenu");
  const return_to = `${window.location.origin}${path_config.base_path}`;

  let pathname = useLocation().pathname;
  const drawerWidth = 210;
  
  function MakeDrawerMenuItem(text, icon, path, index) {
    // console.log("MakeDrawerMenuItem: ", text, path);
    let paddingLeft = 0;
    if (text === "Schedules") {
      paddingLeft = 20;
    }
    let selected = pathname === path
    // console.log("key: ", text+index);
    return (
      <ListItem key={Math.random()} disablePadding style={{ lineHeight: 0, margin: 0, paddingLeft: paddingLeft }}>
        <ListItemButton  style={{ padding: 7 }}
          selected={selected}
          component={Link} to={path}>
          <ListItemIcon style={{minWidth: '40px'}} ><Box color="on_background">{icon}</Box></ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: "on_background" }} primary={text} color="on_background" />
        </ListItemButton>
      </ListItem>
    );
  }

  function makeDepotMenuItem(depot) {
    // console.log("makeDepotMenuItem: ", depot);
    let text = depot.name
    let icon = <EmojiTransportationIcon />

    //  // console.log("key: ", text);
    //  // console.log("key: ", "schedule"+text);
    return (
      <div>
        {MakeDrawerMenuItem(text, icon, `/depots/${depot.id}/charging`, 5)}
        {MakeDrawerMenuItem('Schedules', <ScheduleIcon />, `/schedules/${depot.id}`, 6)}
      </div>
    );
  }  

  const { logout } = useAuth0();
  function MakeDrawerMenuLogout(text, icon) {
    // console.log("MakeDrawerMenuLogout: ", text);  
    // console.log("key: ", text)
    return (
      <ListItem key={Math.random()} disablePadding style={{ lineHeight: 0, margin: 0 }}>
      <ListItemButton onClick={() => logout({returnTo: return_to})}>
        <ListItemIcon><Box color="on_background">{icon}</Box></ListItemIcon>
        <ListItemText primaryTypographyProps={{ color: "on_background" }} primary={text} color="on_background" />
      </ListItemButton>
      </ListItem>
    );
  }

  const { state: depot_state } = useDepots();
  const { depots } = depot_state;

  const DrawerHeader = styled('div')(({ theme }) => ({
    alignItems: 'left',
    justifyContent: 'flex-end',
    border: 2,
  }));

  let sorted_depots = depots.sort((d1, d2) => getTextWidth(d1.name) < getTextWidth(d2.name) ? 1 : -1)

  return (
    <Box sx={{ display: 'flex' }}
      backgroundColor="background"
      width="220px"
      height="100vh"
      display="flex"
      position="relative"
      flexDirection="column"
    >
    <ToastContainer />
    <CssBaseline />
      <Drawer
        sx={{
          height: '100%',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: "#1E344B",
          },
        }}
        variant="permanent"
        anchor="left"
        
      
      >
        <DrawerHeader>
          <OptionsPanelLogo />
          <Box color={"on_background"}>
            <ConnStatus style={{ margin: "5px 10px 10px 10px" }} />
          </Box>
        </DrawerHeader>

        {/* <Toolbar /> */}
        <Divider sx={{ bgcolor: "on_background" }} />
        <StyledList>
          <ListItem sx={{ color: "on_background" }} style={{ lineHeight: 0, margin: 0 }}>
            Vehicles
            </ListItem>
          {MakeDrawerMenuItem('Alerts', <Warning />, "/", 1)}
          {MakeDrawerMenuItem('Map', <PinDrop />, "/map", 2)}
          {MakeDrawerMenuItem('Vehicles', <DirectionsBus />, "/vehicles", 3)}
          {MakeDrawerMenuItem('Gateways', <Router />, "/gateways", 4)}

        </StyledList>
        <Divider sx={{ bgcolor: "on_background" }} />
        <StyledList>
          <ListItem sx={{ color: "on_background" }} style={{ lineHeight: 0, margin: 0 }}>
            Charging
            </ListItem>
          {/* {makeDrawerMenuItem('Charging', <Bolt />)} */}
          {/* {MakeDrawerMenuItem('Schedules', <ScheduleIcon />, "/schedules", 45)} */}

          {sorted_depots.map(d =>
            {return (
              makeDepotMenuItem(d)
            )}
          )}

          {MakeDrawerMenuItem('Alerts', <BatteryAlertIcon />, "/charging_alerts", 50)}
        </StyledList>
        <Divider sx={{ bgcolor: "on_background" }} />
        <StyledList>
          {MakeDrawerMenuLogout('Logout', <MailIcon />)}
        </StyledList>
        <Divider sx={{ bgcolor: "on_background" }} />
      </Drawer>
    </Box>
  );
}
