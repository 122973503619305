import "css/mapbox-gl-new.css";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material/styles";
import { Loading } from "common";
import { theme } from "components";
import { ChargingPage as DepotChargingPage } from "pages/depot";
import { MapProvider } from "react-map-gl";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import history from "./utils/history";

import AlertView from "./pages/AlertView";
import AlertsPage from "./pages/AlertsPage";
import ChargingAlertsPage from "./pages/ChargingAlertsPage";
import GatewayView from "./pages/GatewayView";
import GatewaysPage from "./pages/GatewaysPage";
import MapView from "./pages/MapView";
import RoutePage from "./pages/RoutePage";
import VehicleChargingView from "./pages/VehicleChargingView";
import VehicleView from "./pages/VehicleView";
import VehiclesPage from "./pages/VehiclesPage";
import SchedulesPage from "./pages/SchedulesPage";

// If we want to do code splitting down the routes
// const VehiclesPage = React.lazy(() => import("./pages/VehiclesPage"))
// const AlertsPage = React.lazy(() => import("./pages/AlertsPage"))
// const MapView = React.lazy(() => import("./pages/MapView"))
// const VehicleView = React.lazy(() => import("./pages/VehicleView"))
// const VehicleChargingView = React.lazy(() => import("./pages/VehicleChargingView"))

export default function App({ base_path }) {
  const PrivateAlertsPage = withAuthenticationRequired(AlertsPage, { onRedirecting: () => <Loading /> });
  const PrivateAlertView = withAuthenticationRequired(AlertView, { onRedirecting: () => <Loading /> });
  const PrivateMapView = withAuthenticationRequired(MapView, { onRedirecting: () => <Loading /> });
  const PrivateChargingView = withAuthenticationRequired(VehicleChargingView, { onRedirecting: () => <Loading /> });
  const PrivateVehicleView = withAuthenticationRequired(VehicleView, { onRedirecting: () => <Loading /> });
  const PrivateVehiclesPage = withAuthenticationRequired(VehiclesPage, { onRedirecting: () => <Loading /> });
  const PrivateGatewayView = withAuthenticationRequired(GatewayView, { onRedirecting: () => <Loading /> });
  const PrivateGatewaysPage = withAuthenticationRequired(GatewaysPage, { onRedirecting: () => <Loading /> });
  const PrivateDepotChargingPage = withAuthenticationRequired(DepotChargingPage, { onRedirecting: () => <Loading /> });
  const PrivateChargingAlertsPage = withAuthenticationRequired(ChargingAlertsPage, { onRedirecting: () => <Loading /> });
  const PrivateSchedulesPage = withAuthenticationRequired(SchedulesPage, { onRedirecting: () => <Loading /> });
  const PrivateRoutesPage = withAuthenticationRequired(RoutePage, { onRedirecting: () => <Loading /> });

  return (
    <ThemeProvider theme={theme}>
      <MapProvider>
        <Router history={history} basename={base_path}>
          {/* <React.Suspense fallback={<Loading />}> */}
          <Routes>
            <Route path="/" element={<PrivateAlertsPage />} />
            <Route path="/login" element={<div>Requires Authentication</div>} />
            <Route path="/map" element={<PrivateMapView />} />
            <Route path="/vehicles" element={<PrivateVehiclesPage />} />
            <Route path="/vehicle/charging/:vehicle_id" element={<PrivateChargingView />} />
            <Route path="/vehicle/:vehicle_id" element={<PrivateVehicleView />} />
            <Route path="/gateways" element={<PrivateGatewaysPage />} />
            <Route path="/gateway/:gateway_id" element={<PrivateGatewayView />} />
            <Route path="/alerts" element={<PrivateAlertsPage />} />
            <Route path="/alert/:alert_id" element={<PrivateAlertView />} />
            <Route path="/depots/:id/charging" element={<PrivateDepotChargingPage />} />
            <Route path="/route/:route_id" element={<PrivateRoutesPage />} />
            <Route path="/charging_alerts" element={<PrivateChargingAlertsPage />} />
            <Route path="/schedules/:depot_id" element={<PrivateSchedulesPage />} />
            <Route path="*" element={<div>Not Found</div>} />
          </Routes>
          {/* </React.Suspense> */}
        </Router>
      </MapProvider>
    </ThemeProvider>
  );
};
